import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useOrder } from '../../../contexts/OrderContext';
import './CheckoutTab.View.scss';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutContent from './CheckoutContent.View';

const stripePromise = loadStripe('pk_test_51IdiDtFh7Dasx8MokymoBsEmIltaHNS1Rv1wfTzwJ8LB0ndtWHyuPccrv4r46xEd7ITa2HbEo9Qci6xZXMadIO7C00KVTZEEwl');

const defaultAppearance = {
    theme: 'stripe',
    labels: 'floating',
    rules: {
        '.Input:focus': {
            boxShadow: 'none',
            outline: 0,
            borderColor: '#000000',
        },
    },
};

const CheckoutTab = () => {
    const { cartItems, priceDetails } = useOrder();

    const [appearance, setAppearance] = useState({
        time: Date.now(),
        data: defaultAppearance,
    });

    useEffect(() => {
        // Ensure the appearance object triggers re-render for Stripe Elements
        setAppearance((prev) => ({ ...prev, time: Date.now() }));
    }, []);

    const totalPrice = useMemo(() => {
        return cartItems.reduce((acc, curr) => acc + curr.price * curr.quantity, 0);
    }, [cartItems]);

    return (
        <Fragment>
            {stripePromise ? (
                <div key={appearance.time}>
                    <Elements
                        stripe={stripePromise}
                        options={{
                            appearance: appearance.data,
                            mode: 'payment',
                            amount: totalPrice,
                            currency: priceDetails?.currency_code?.toLowerCase(),
                        }}>
                        <CheckoutContent />
                    </Elements>
                </div>
            ) : (
                <div className="error-message">
                    <p>Failed to load the payment form. Please try refreshing the page or contact support.</p>
                </div>
            )}
        </Fragment>
    );
};

export default CheckoutTab;
