import React, {Fragment} from 'react';
import {Accordion, Card} from 'react-bootstrap';
import {useOrder} from '../../../contexts/OrderContext';
import {usePage} from '../../../contexts/PageContext';
import {formatPriceStatic, getCookie, retailAPI} from '../../../utils/common';
import CheckoutTab from '../checkout-tab/CheckoutTab.View';
import RequisiteInformationTab from '../requisite-information-tab';
import NowShowingTab from '../now-showing-tab';
import OrderList from '../order-list/OrderList.View';
import './CollapseHeader.View.scss';
import {SingleListItem} from "./SingleItem.View";

const CollapseHeader = () => {
    const {totalCartItemsPrice, totalCartItems, cartItems, priceDetails} = useOrder();
    const {activeMenuItem, setActiveMenuItem} =
        usePage();

    const headerTabItems = [
        {
            id: '1',
            tabItemName: 'Now Showing',
            tabItemBody: <NowShowingTab/>,
        },
        {
            id: '2',
            tabItemName: 'Requisite Information',
            tabItemBody: <RequisiteInformationTab/>,
        },
        {
            id: '3',
            tabItemName: `Your Order`,
            tabItemBody: <OrderList/>,
        },

        ...(cartItems?.length
            ? [
                {
                    id: '4',
                    tabItemName: 'Checkout',
                    tabItemBody: Number(activeMenuItem) === 4 ? <CheckoutTab/> : <Fragment/>,
                },
            ]
            : []),
    ];

    const handleAccordionSelect = (selectedKey) => {
        if (Number(selectedKey) === 3) {
            const productDetails = cartItems.map((art) => ({
                product: {id: art.art_id},
            }));

            const postData = {
                eventType: 'shopping-cart-page-view',
                eventTime: new Date().toISOString(),
                userInfo: {
                    userAgent: navigator.userAgent,
                    ipAddress: '192.168.1.100', // Replace with a real IP fetch logic if needed.
                },
                visitorId: getCookie('_ga'),
                productDetails,
                cartId: '0000',
                attributes: {
                    global_website: {
                        text: [window.location.host],
                        indexable: true,
                        searchable: true,
                    },
                },
            };

            // Trigger the API call
            void retailAPI(postData);
        }

        // Toggle active menu item
        setActiveMenuItem((prevKey) => (prevKey === selectedKey ? null : selectedKey));
    };


    return (
        <div className='collapse-header-container'>
            <Accordion
                activeKey={activeMenuItem}
                onSelect={handleAccordionSelect}
            >
                {headerTabItems?.map((item) => (
                    <Card key={item?.id}>
                        <Card.Header>
                            <SingleListItem eventKey={item.id}>
                                {item.tabItemName === 'Your Order' ? (
                                    <Fragment>
                                        {totalCartItems > 0 ? (
                                            <span>
                                                <span>{item.tabItemName} </span>
                                                <span className='total-item-price'>
                                                    ({totalCartItems}
                                                    {totalCartItems > 1 ? ' Items, ' : ' Item, '}
                                                    {formatPriceStatic(
                                                        totalCartItemsPrice,
                                                        priceDetails?.currency_code
                                                    )}
                                                    )
                                                </span>
                                            </span>
                                        ) : (
                                            <span>{item.tabItemName} </span>
                                        )}
                                    </Fragment>
                                ) : item.tabItemName === 'Show' ? (<span>{`Show`}</span>) : (
                                    item.tabItemName
                                )}
                            </SingleListItem>
                        </Card.Header>
                        <Accordion.Collapse eventKey={item.id}>
                            <Card.Body>{item.tabItemBody}</Card.Body>
                        </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>
        </div>
    );
};
export default CollapseHeader;
