import React from "react";
import {usePage} from "../../../contexts/PageContext";
import {useOrder} from '../../../contexts/OrderContext';
import {formatPriceStatic} from "../../../utils/common";
import ItemCounter from "./ItemCounter";
import "./SingleOrderItem.View.scss";
import {useNavigate} from "react-router-dom";

const SingleOrderItem = ({
                             // artist_name,
                             // art_name,
                             // id,
                             // art_id,
                             // price,
                             // itemSize,
                             // img,
                             // imgBW,
                             // imgTB,
                             item,
                             cartItemsLength,
                         }) => {
    const {
        showingRef,
        setShowing,
        handleClickMenubarOpen,
        setCurrentSlider,
        setSelectedCartItem,
    } = usePage();
    const {priceDetails} = useOrder();
    const navigate = useNavigate();

    const ITEM_IMAGES = {
        "TrueBorder 20": {
            label: (
                <>
                    TrueBorder<small><sup>TM</sup></small> 20
                </>
            ),
            type: "TB",
            img: item?.imgTB,
        },
        "TrueBorder 40": {
            label: (
                <>
                    TrueBorder<small><sup>TM</sup></small> 40
                </>
            ),
            type: "TB",
            img: item?.imgTB,
        },
        "Black and White 20": {
            label: <>Black and White 20</>,
            type: "BW",
            img: item?.imgBW,
        },
        "Black and White 40": {
            label: <>Black and White 40</>,
            type: "BW",
            img: item?.imgBW,
        },
    };

    /* previous onClickCartItem function */
    // const onClickCartItem = () => {
    //     if (!cartItemsLength) {
    //         alert(`Your order is currently empty.`);
    //         return;
    //     }
    //     setShowing((prev) => {
    //         showingRef.current = prev;
    //         return "orderList";
    //     });
    //     setCurrentSlider("orders");
    //     setSelectedCartItem(item);
    //     handleClickMenubarOpen();
    //     navigate(`/product/${item.art_id}`);
    // };

    const onClickCartItem = () => {
        handleClickMenubarOpen()
        setTimeout(() => {
            if (!cartItemsLength) {
                alert(`Your order is currently empty.`);
                return;
            }
            setShowing((prev) => {
                showingRef.current = prev;
                return "orderList";
            });
            setCurrentSlider("orders");
            setSelectedCartItem(item);
            navigate(`/product/${item.art_id}`);
        }, 500)
    };

    return (
        <div className="single-order-item-container">
            <div className="single-order-item-left" onClick={() => onClickCartItem()}>
                <img src={ITEM_IMAGES[item?.itemSize]?.img || item?.img}
                     loading="lazy" alt=""/>
            </div>
            <div className="single-order-item-content">
                <div className="top-content">
                    <h4 className="author-name">
                        "{item?.art_name}" by {item?.artist_name}
                    </h4>
                    <h4>{ITEM_IMAGES[item?.itemSize]?.label}</h4>
                    <h4>
                        {formatPriceStatic(
                            item?.price,
                            priceDetails?.currency_code
                        )}
                    </h4>
                    <h4>
                        Item No. <span>{item?.art_id}</span>
                    </h4>
                </div>
                <ItemCounter itemID={item?.art_id} id={item?.id}/>
            </div>
        </div>
    );
};
export default SingleOrderItem;
