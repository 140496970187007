import React, {useEffect, useState} from 'react';
import {History, Keyboard, Manipulation, Mousewheel, Navigation} from 'swiper/modules';

import {Swiper, SwiperSlide} from 'swiper/react';
import UseWindowDimensions from '../../../../components/snippets/use-window-dimensions';

import {useOrder} from '../../../../contexts/OrderContext';
import {usePage} from '../../../../contexts/PageContext';
import TouchDeviceOption from "./TouchDeviceOption";
import NoTouchOption from "./NoTouchOption";
import {useNavigate} from "react-router-dom";
import './Sliders.scss';

const CartSlides = ({onSlide}) => {
    const [swiper, setSwiper] = useState();
    const navigate = useNavigate();
    const {
        cartItems,
    } = useOrder();
    const {height} = UseWindowDimensions();

    const {
        showing,
        setTransitionStarted,
        currentSlider,
        setZoomedProductId,
        setZoomed,
        idFromUrl,
        isTouchDevice,
        setZoomedProduct,
        reset,
        setActiveMenu,
        zoomedProduct,
        zoomed
    } = usePage();

    const setCurrentState = (swiper) => {
        if (!swiper || !swiper.slides) return;
        const currentData = sliderImages[swiper.activeIndex];
        onSlide(currentData.art_id, currentData.art_name, currentData.artist_name)
    }

    const onInit = async (swiper) => {
        if (!swiper) return;
        setSwiper(swiper);

        if (idFromUrl && swiper?.slides) {
            navigate(`/products/${cartItems[swiper.activeIndex].art_id}`);
        }
    }

    const sliderImages = cartItems || [];

    useEffect(() => {
        if (!sliderImages.length) {
            reset()
        }
    }, [sliderImages])

    return (
        <div className='slide-wrapper'>
            <Swiper
                key={`${showing}`}
                modules={[
                    Navigation,
                    History,
                    Keyboard,
                    Manipulation,
                    Mousewheel,
                ]}
                history={{
                    key: 'product'
                }}
                onSlideNextTransitionStart={() => {
                    setTransitionStarted(Date.now());
                }}
                onActiveIndexChange={async (swiper) => {
                    setZoomed(false);
                    setZoomedProductId(null);
                    setZoomedProduct(null);
                    await setCurrentState(swiper)
                }}
                onInit={async (swiper) => {
                    setActiveMenu('orders')
                    await setCurrentState(swiper)
                    await onInit(swiper)
                }}
                speed={400}
                autoHeight={true}
                grabCursor={true}
                simulateTouch={true}
                mousewheel={true}
                keyboard={true}
                touchStartPreventDefault={false}
            >
                {sliderImages.map((item) => (
                    <SwiperSlide
                        key={`products_${currentSlider}_${item.art_id}`}
                        data-history={item.art_id}
                        data-title={`${item.art_name} by ${item.artist_name} | FloatPic.com`}
                        className={`d-flex flex-column align-items-center justify-content-center w-100 [data-history="${item.art_id}"]`}
                    >
                        <div className={`swiper-slide-img-container `}
                             style={{height: height}}
                             data-msg={`${item.art_name} by ${item.artist_name}`}
                        >
                            <div className="relative">
                                {!isTouchDevice ? (
                                    <TouchDeviceOption item={item} setShowBorderSwitchId={window.innerWidth < 600}/>
                                ) : (
                                    <NoTouchOption item={item} setShowBorderSwitchId={window.innerWidth < 600}/>
                                )}
                            </div>
                        </div>

                        <div className="slider-contents slider-contents--cart">
                            <div
                                className='cart-slider-zoom'
                                onClick={() => {
                                    setZoomedProductId(item?.art_id);

                                    if (zoomedProduct && zoomedProduct.art_id === item?.art_id) {
                                        setZoomedProduct(null)
                                    } else {
                                        setZoomedProduct(item);
                                    }
                                }}
                            >
                                <img
                                    src={zoomed ? '/images/zoom-close.png' : '/images/zoom.png'}
                                    alt={zoomed ? 'zoom out' : 'zoom in'}
                                    width={14}
                                    loading="lazy"
                                    className='cart-slider-zoom__icon'
                                />
                            </div>
                            <p className="slider-contents__name"
                               style={{display: 'hidden'}}
                               data-msg={`${item.art_name} by ${item.artist_name}`}
                            >
                                {item.art_name} by {item.artist_name}
                            </p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};
export default CartSlides;
