import {createContext, useContext, useEffect, useRef, useState} from 'react';
import {useSessionStorage} from '../hooks/useSessionStorage';
import {useLocalStorage} from "../hooks/useLocalStorage";
import {useComboboxControls} from 'react-datalist-input';
import {useNavigate} from 'react-router-dom';

const PageContext = createContext(null);
export const usePage = () => useContext(PageContext);

const PageProvider = ({children}) => {
    const [showing, setShowing] = useSessionStorage('showing', 'home');
    const [activeShopTab, setActiveShopTab] = useSessionStorage('activeShopTab', 'SEARCH'); // Value will be 'BROWSE' or 'SEARCH'
    const [selectedCartItem, setSelectedCartItem] = useSessionStorage('selectedCartItem', null);
    const [currentSlider, setCurrentSlider] = useSessionStorage('currentSlider', null);
    const [loading, setLoading] = useState(false);
    const [clientSecret, setClientSecret] = useState('');
    const [zoomed, setZoomed] = useState(false);

    const [borderPreference, setBorderPreference] = useSessionStorage('borderPreference', 'TB');
    const showingRef = useRef();
    const [swiperProduct, setSwiperProduct] = useState(null);
    const [filterCleared, setFilterCleared] = useState(false);
    const [transitionStarted, setTransitionStarted] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState(null);

    const [country, setCountry] = useSessionStorage('country', '');
    const [floatPictures, setFloatPictures] = useSessionStorage('floatPictures', []);
    const [filteredFloatPictures, setFilteredFloatPictures] = useSessionStorage(
        'filteredFoatPictures',
        []
    );
    const [filterValue, setFilterValue] = useSessionStorage('filterValue', '');
    const [zoomedProduct, setZoomedProduct] = useState(null);
    const [zoomedProductId, setZoomedProductId] = useState(null);
    const [isMenubarOpen, setIsMenubarOpen] = useState(false);
    const [artTimes, setArtTimes] = useSessionStorage('artTimes', null);
    const [currentSlide, setCurrentSlide] = useSessionStorage('currentSlide', null);
    const [stopReload, setStopReload] = useSessionStorage('stopReload', false);
    const [previousArtId, setPreviousArtId] = useSessionStorage('previous_art_id', null);
    const [comboboxValue, setComboboxValue] = useSessionStorage('comboboxValue', null);
    const [allProductsclicked, setAllProductsclicked] = useSessionStorage('allProductsclicked', null);
    const [noReload, setNoReload] = useSessionStorage('noReload', false);
    const [showOnboarding, setShowOnboarding] = useLocalStorage('showonboardslidearrow', true);
    const [isAddToCartOpen, setIsAddToCartOpen] = useState(false);
    const isTouchDevice = 'ontouchstart' in document.documentElement || navigator?.msMaxTouchPoints;
    const [activeMenu, setActiveMenu] = useLocalStorage('activeMenu', 'home');
    const [immediatelyPreviousActiveMenu, setImmediatelyPreviousActiveMenu] = useState('home')

    // artish input state
    const {
        setValue: setArtistInputValue,
        value: artistInputValue,
        isExpanded: artistInputIsExpanded,
        setIsExpanded: setArtistInputIsExpanded,
    } = useComboboxControls({
        initialValue: '',
        isExpanded: false,
    });
    const [filteredData, setFilteredData] = useState([]);
    const navigate = useNavigate();

    const handelMenubarOpen = (isOpen = isMenubarOpen, timeout = 200) => {
        setTimeout(() => {
            setIsMenubarOpen(isOpen)
        }, timeout)
    }

    const handleClickMenubarOpen = () => {
        handelMenubarOpen(!isMenubarOpen)

        if (isMenubarOpen && activeMenu === 'artist' && !artistInputValue && immediatelyPreviousActiveMenu === 'home') {
            setActiveMenu('home')
            // handleTabSelect('recommendations')
        } else if (isMenubarOpen && activeMenu === 'artist' && !artistInputValue && immediatelyPreviousActiveMenu === 'recommendations') {
            setActiveMenu('recommendations')
            handleTabSelect('recommendations')
        }
    };

    const getIdFromUrl = () => {
        const url = window.location.pathname.split('/')
        if (url.length > 2) {
            return url[2]
        }
        return ''
    }

    const handleTabSelect = (value) => {
        if (value !== 'artist') {
            handelMenubarOpen(false)
        }

        if (value === 'recommendations') {
            setActiveShopTab('BROWSE');
            setTimeout(() => {
                setNoReload(true);
                setAllProductsclicked(true);
                setShowing((prev) => {
                    showingRef.current = prev;
                    return 'allProducts';
                });
                setFilterValue('');
                setFilteredData([]);
                setArtistInputValue('');
                setCurrentSlider('products');
                if (allProductsclicked === 'false') {
                    floatPictures.length &&
                    setCurrentSlide(floatPictures[0].art_id);
                }
                if (currentSlide === null) {
                    if (floatPictures.length > 1) {
                        navigate(`/product/${floatPictures[0]?.art_id}`);
                    } else {
                        setStopReload(true);
                    }
                } else {
                    navigate(`/product/${currentSlide}`);
                }
            }, 500);
        } else if (value === 'orders') {
            setTimeout(() => {
                setShowing((prev) => {
                    showingRef.current = prev;
                    return 'orderList';
                });
                setCurrentSlider('orders');
            }, 200)
        } else if (value === 'home') {
            setTimeout(() => {
                reset();
                navigate('/');
            }, 200)
        }
    }

    const reset = () => {
        setShowing('home');
        setActiveShopTab("SEARCH");
        setSelectedCartItem(null);
        handelMenubarOpen(false);
        setFloatPictures([]);
        // setCountry('');
        setBorderPreference('TB');
        setSwiperProduct(null);
        setFilterCleared(false);
        setTransitionStarted(false);
        setFilteredFloatPictures([]);
        setFilterValue('');
        setCurrentSlider('');
        setLoading(false);
        setActiveMenuItem(null);
        setClientSecret('');
        setZoomedProductId(null);
        setZoomed(false);
        setArtTimes(null);
        setCurrentSlide(null);
        setStopReload(false);
        setPreviousArtId(null);
        setComboboxValue(null);
        setAllProductsclicked(null);
        setNoReload(false)
        setShowOnboarding(true);
        setIsAddToCartOpen(false);
        setZoomedProduct(null);
        setActiveMenu('home');

        if (document.location.pathname !== '/') {
            document.location.href = '/';
        }
        // document.title = `FloatPic ${COUNTRY_DATA[country]?.name} Official Website | The ultimate physical representation of a still image for wall display`;
    }

    useEffect(() => {
        if (!isMenubarOpen) {
            setActiveMenuItem(null);
        }
    }, [isMenubarOpen]);

    const value = {
        showing,
        setShowing,
        activeShopTab,
        setActiveShopTab,
        selectedCartItem,
        setSelectedCartItem,
        handleClickMenubarOpen,
        setIsMenubarOpen,
        handelMenubarOpen,
        isMenubarOpen,
        floatPictures,
        setFloatPictures,
        country,
        setCountry,
        showingRef,
        setBorderPreference,
        borderPreference,
        swiperProduct,
        setSwiperProduct,
        setFilterCleared,
        filterCleared,
        transitionStarted,
        setTransitionStarted,
        filteredFloatPictures,
        setFilteredFloatPictures,
        filterValue,
        setFilterValue,
        currentSlider,
        setCurrentSlider,
        loading,
        setLoading,
        activeMenuItem,
        setActiveMenuItem,
        clientSecret,
        setClientSecret,
        zoomedProductId,
        setZoomedProductId,
        zoomed,
        setZoomed,
        idFromUrl: getIdFromUrl(),
        setArtTimes,
        artTimes,
        currentSlide,
        setCurrentSlide,
        stopReload,
        setStopReload,
        previousArtId,
        setPreviousArtId,
        comboboxValue,
        setComboboxValue,
        allProductsclicked,
        setAllProductsclicked,
        noReload,
        setNoReload,
        showOnboarding,
        setShowOnboarding,
        isAddToCartOpen,
        setIsAddToCartOpen,
        zoomedProduct,
        setZoomedProduct,
        isTouchDevice,
        setActiveMenu,
        activeMenu,
        reset,
        artistInputValue,
        setArtistInputValue,
        artistInputIsExpanded,
        setArtistInputIsExpanded,
        filteredData,
        setFilteredData,
        handleTabSelect,
        immediatelyPreviousActiveMenu,
        setImmediatelyPreviousActiveMenu
    };
    return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

export default PageProvider;
