import React, {memo, useEffect, useRef, useState} from 'react';
import DatalistInput, {includesValueFilter} from 'react-datalist-input';
import 'react-datalist-input/dist/styles.css';
import {usePage} from '../../../contexts/PageContext';
import authors from '../../../data/authors.json';
import './NowShowingTab.View.scss';
import {Form} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useOrder} from "../../../contexts/OrderContext";
import {appApiUrl} from "../../../utils/config";

const NowShowingTab = memo(() => {
    const {
        showingRef,
        setShowing,
        borderPreference,
        filteredFloatPictures,
        setFilteredFloatPictures,
        transitionStarted,
        setFilterValue,
        filterValue,
        handelMenubarOpen,
        isMenubarOpen,
        setCurrentSlider,
        comboboxValue,
        setComboboxValue,
        setActiveMenu,
        activeMenu,
        artistInputValue,
        setArtistInputValue,
        artistInputIsExpanded,
        setArtistInputIsExpanded,
        filteredData,
        setFilteredData,
        handleTabSelect,
        setImmediatelyPreviousActiveMenu
    } = usePage();

    const {totalCartItems} = useOrder();
    const navigate = useNavigate();


    // console.log({artistInputValue});

    const showTabItems = {
        recommendations: {
            id: 1,
            name: 'Continuous Recommendations',
            icon: '/images/recommendations.svg',
            active: true
        },
        artist: {
            id: 2,
            name: 'Artist Search',
            icon: '/images/artists.svg',
            active: true,
        },
        orders: {
            id: 3,
            name: 'Items in Your Order',
            icon: '/images/cart-dark.svg',
            active: !!totalCartItems,
        },
        home: {
            id: 4,
            name: 'Homepage',
            icon: '/images/homepage.svg',
            active: true
        },
    }


    useEffect(() => {
        if (!isMenubarOpen && artistInputValue) {
            if (!authors.some(([artistName]) => artistName === artistInputValue)) {
                setArtistInputValue('');
            }
        }
    }, [isMenubarOpen, artistInputValue]);

    // Retrieve stored value from sessionStorage on component mount
    useEffect(() => {
        if (comboboxValue) {
            setArtistInputValue(comboboxValue);
        }
    }, [setArtistInputValue]);

    // Synchronize filterValue with value
    useEffect(() => {
        setArtistInputValue(filterValue);
    }, [filterValue]);

    // Persist value to sessionStorage when it changes
    useEffect(() => {
        setComboboxValue(artistInputValue);
    }, [artistInputValue]);

    const inputRef = useRef(null);
    const [totalProducts, setTotalProducts] = useState();

    // Set filterValue if the value matches an artist
    useEffect(() => {
        if (artistInputValue && authors.some(([artistName]) => artistName === artistInputValue)) {
            setFilterValue(artistInputValue);
        }
    }, [artistInputValue]);

    const fetchData = async (amount = 5, artistName) => {
        try {
            let response = await fetch(
                `${appApiUrl}/load_new_products/?artist_name=${artistName}&no_of_items=${amount}`
            );
            let data = await response.json();
            let transformedData =
                data?.products_data.length &&
                data?.products_data.map(([art_id, art_name, artist_name, imgTB, imgBW]) => {
                    return {
                        art_id,
                        art_name,
                        artist_name,
                        imgTB,
                        imgBW,
                        img: borderPreference === '-TB' ? imgTB : imgBW,
                    };
                });
            setTotalProducts(data?.total_products);
            return transformedData;
        } catch (error) {
            console.error(error);
        }
    };

    // Fetch data if the value matches an artist
    useEffect(() => {
        if (artistInputValue && authors.some(([artistName]) => artistName === artistInputValue)) {
            // setLoading(true);
            if (filteredFloatPictures.length && filteredFloatPictures[0].artist_name === artistInputValue) {
                setCurrentSlider('filteredProducts');
                // setLoading(false);
                return;
            }
            (async () => {
                try {
                    const combinedData = await fetchData(undefined, artistInputValue);
                    setFilteredFloatPictures(() => {
                        const updatedPictures = [...combinedData];
                        return Array.from(
                            new Map(updatedPictures.map((item) => [item?.art_id, item])).values()
                        );
                    });
                    setTimeout(() => {
                        navigate(`/product/${combinedData[0].art_id}`);
                        inputRef?.current?.querySelector('input').blur();
                    }, 110);
                    // handelMenubarOpen(false);
                } catch (error) {
                    console.log(error);
                } finally {
                    setCurrentSlider('filteredProducts');
                    // setLoading(false);
                }
            })();
        }
    }, [artistInputValue]);

    useEffect(() => {
        if (filteredData.length >= 2) {
            const pictures = filteredData.map((item) => {
                item.img = borderPreference === '-BW' ? item.imgBW : item.imgTB;
                return item;
            });
            setFilteredFloatPictures((prev) => {
                const updatedPictures = [...prev, ...pictures];
                return Array.from(
                    new Map(updatedPictures.map((item) => [item?.art_id, item])).values()
                );
            });
            setFilteredData([]);
        }
    }, [filteredData]);

    // Fetch more data if transitionStarted or artistInputValue changes
    useEffect(() => {
        if (artistInputValue && authors.some(([artistName]) => artistName === artistInputValue)) {
            (async () => {
                try {
                    if (filteredFloatPictures.length < totalProducts) {
                        const combinedData = await fetchData(3, artistInputValue);
                        setFilteredData((prev) => [...prev, ...combinedData]);
                    }
                } catch (error) {
                    console.log(error);
                }
            })();
        }
    }, [transitionStarted, artistInputValue, filteredFloatPictures.length, totalProducts]);

    return (
        <div className='now-showing-tab-container'>
            {/* eslint-disable-next-line array-callback-return */}
            {Object.keys(showTabItems).map((key, index) => {
                if (showTabItems[key].active) {
                    return (
                        <div key={index} className="now-showing-item">
                            <Form.Check className="now-showing-item__check"
                                        type="radio" name="showing"
                                        id={`showing-${showTabItems[key].id}`}>
                                <Form.Check.Input
                                    type="radio"
                                    checked={key === activeMenu} isValid
                                    onChange={(e) => {
                                        if (e.target.id === `showing-${showTabItems[key].id}` && e.target.checked) {
                                            setImmediatelyPreviousActiveMenu(activeMenu)
                                            setActiveMenu(key)
                                            handleTabSelect(key)
                                        }
                                    }}/>
                                <Form.Check.Label>
                                    <img src={showTabItems[key].icon}
                                         alt={showTabItems[key].name}
                                         loading="lazy"
                                         className="now-showing-item__icon"/>
                                    {showTabItems[key].name}
                                </Form.Check.Label>
                            </Form.Check>

                            {(showTabItems[key].id === 2 && showTabItems[activeMenu].id === 2) ? (
                                <div className="relative py-2">
                                    <div className='input-container'>
                                        <DatalistInput
                                            ref={inputRef}
                                            value={artistInputValue}
                                            onClick={(e) => e.preventDefault()}
                                            isExpanded={artistInputIsExpanded}
                                            setIsExpanded={setArtistInputIsExpanded}
                                            filters={[(items) => (artistInputValue ? items : []), includesValueFilter]}
                                            setValue={setArtistInputValue}
                                            onSelect={() => {
                                                handelMenubarOpen(false);
                                                setShowing((prev) => {
                                                    showingRef.current = prev;
                                                    return 'allProducts';
                                                });
                                            }}
                                            autoFocus={Boolean(artistInputValue)}
                                            onBlur={(item) => {
                                                if (!item.target.value) {
                                                    setCurrentSlider('products');
                                                    setFilterValue('');
                                                    setFilteredData([]);
                                                }
                                            }}
                                            items={authors.map((item, index) => ({
                                                id: index,
                                                value: item[0],
                                            }))}
                                            label=""
                                        />
                                        <label className={artistInputValue && 'filled'}>Type artist name</label>
                                        {artistInputValue && (
                                            <button
                                                className='remove-filter-btn'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setArtistInputValue('');
                                                    inputRef?.current?.querySelector('input').focus();
                                                }}
                                            >
                                                X
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ) : <></>}
                        </div>
                    )
                }
            })}
        </div>
    );
});

export default NowShowingTab;
