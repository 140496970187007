import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useOrder } from "../../../contexts/OrderContext";
import { usePage } from "../../../contexts/PageContext";
import { formatPriceStatic, getCookie, retailAPI } from "../../../utils/common";
import Button from "../../ui/button/Button";
import CheckoutPayment from "../checkout-payment";
import "./CheckoutTab.View.scss";
import { appApiUrl, appSubdomain } from "../../../utils/config";

const CheckoutContent = () => {
  const { totalCartItemsPrice, cartItems, setCartItems, priceDetails } =
    useOrder();
  const { handelMenubarOpen, setClientSecret, country } = usePage();
  const stripe = useStripe();
  const elements = useElements();

  const [processing, setProcessing] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryName, setCountryName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [name, setName] = useState("");
  const [isPaymentElementComplete, setIsPaymentElementComplete] =
    useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (!elements) return;

    const paymentElement = elements.getElement(PaymentElement);

    if (paymentElement) {
      paymentElement.on("change", (event) => {
        setIsPaymentElementComplete(event.complete);
      });
    }
  }, [elements]);

  const pushPurchaceData = () => {
    const postData = {
      eventType: "purchase-complete",
      eventTime: new Date().toISOString(),
      userInfo: {
        userAgent: navigator.userAgent,
        ipAddress: "", // Replace with dynamic IP fetcher if required
      },
      visitorId: getCookie("_ga"),
      productDetails: [],
      attributes: {
        global_website: {
          text: [window.location.host],
          indexable: true,
          searchable: true,
        },
      },
    };

    return retailAPI(postData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    const errors = {};
    if (!name) errors.name = "Full name is required.";
    if (!email) errors.email = "Email is required.";
    if (!phoneNumber) errors.phoneNumber = "Phone number is required.";
    if (!countryName) errors.countryName = "Country is required.";
    if (!addressLine1) errors.addressLine1 = "Address line 1 is required.";
    if (!city) errors.city = "City is required.";
    if (!postalCode) errors.postalCode = "Postal code is required.";
    if (!isPaymentElementComplete)
      errors.payment = "Card information is incomplete.";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setProcessing(false);
      return;
    }

    setFormErrors({});
    setProcessing(true);

    if (!stripe || !elements) {
      setProcessing(false);
      return;
    }

    // Submit the payment form first
    const submitResult = await elements.submit();
    if (submitResult.error) {
      alert(submitResult.error.message);
      setProcessing(false);
      return;
    }

    // Fetch the client secret
    const metadata = await cartItems.map(
      ({ art_id, art_name, artist_name, quantity, itemSize: artsize }) => ({
        artsize,
        art_id,
        art_name,
        artist_name,
        quantity,
      })
    );
    const totalPrice = await cartItems.reduce(
      (acc, curr) => acc + curr.price * curr.quantity,
      0
    );

    const response = await fetch(`${appApiUrl}/create-payment-intent/`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      body: JSON.stringify({
        amount: totalPrice,
        active_country: country || "us",
        metadata,
      }),
    });

    const clientSecretData = await response.json();
    const clientSecret = clientSecretData?.resp?.client_secret;

    if (!clientSecret) {
        setProcessing(false);
        throw new Error('Failed to fetch client secret');
    }

    // Confirm the payment
    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${appSubdomain()}/checkout-success`,
        payment_method_data: {
          billing_details: {
            name,
            email,
            phone: phoneNumber,
          },
        },
      },
      redirect: "if_required",
    });

    if (error) {
      alert(error.message);
    } else {
      handelMenubarOpen(false);
      setCartItems([]);
      setClientSecret("");
      await pushPurchaceData();
      alert(
        "Your order has been successfully received. An email receipt will be sent shortly."
      );
    }

    setProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <CheckoutPayment
          setEmail={setEmail}
          setName={setName}
          setPhoneNumber={setPhoneNumber}
          setCountry={setCountryName}
          setAddressLine1={setAddressLine1}
          setCity={setCity}
          setPostalCode={setPostalCode}
        />
        {Object.keys(formErrors).length > 0 && (
          <div className="form-errors">
            {Object.values(formErrors).map((error, index) => (
              <p key={index} className="error-text">
                {error}
              </p>
            ))}
          </div>
        )}
      </div>

      <Button
        disabled={!stripe || processing}
        type="submit"
        text={
          processing ? (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              {"Processing your order"}
              <span className="loader-circle"></span>
            </span>
          ) : (
            `Pay ${formatPriceStatic(
              totalCartItemsPrice,
              priceDetails?.currency_code
            )}`
          )
        }
        className="save-btn"
        aria-busy={processing}
        aria-live="polite"
      />
    </form>
  );
};

export default CheckoutContent;
